/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { trackEvent } from '../utils/eventTrack'
import { scannerGateHsFormCollect } from './form/hubspot/HubSpotFormAPI'
import SEO from './SEO'
import useUrlParam from '../utils/useUrlParam'
import ResultsContent from './ResultsContent'
import ScannerProgress from './ScannerProgress'
import checkSiteValidity from './form/Onboarding/checkSiteValidity'
import { getUrlId, fetchUrlScore } from '../utils/scan/scan-utils'
import { useShowConsentForm } from '../context/ConsentContext'
import generateScanResultsPageTranslations from '../data/generateScanResultsPageTranslations'
import { useUserLang } from '../context/UserLangContext'

function useInterval(callback, delay) {
  const savedCallback = useRef()
  const intervalId = useRef()

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    const tick = async () => {
      await savedCallback.current()
    }

    if (delay !== null) {
      intervalId.current = setInterval(tick, delay)

      // Clear the interval when the component is unmounted or when delay changes
      return () => clearInterval(intervalId.current)
    }
  }, [delay])

  // Expose a clear function
  const clear = () => clearInterval(intervalId.current)

  return clear
}

const ScannerContent = () => {
  const windowGlobal = typeof window !== `undefined` && window
  const [scanUrl, setScanUrl] = useState(windowGlobal ? useUrlParam(window.location.search, 'url') : '')
  const [urlId, setUrlId] = useState(windowGlobal ? useUrlParam(window.location.search, 'uuid') : '')
  const [mktg] = useState(windowGlobal ? useUrlParam(window.location.search, 'mktg') === 'true' : false)
  const [partner] = useState(windowGlobal ? useUrlParam(window.location.search, 'partner') === 'true' : false)
  const { userLang } = useUserLang()
  const [showGateForm] = useState(windowGlobal && window.sessionStorage.getItem('gate'))
  const [dataLoaded, setDataLoaded] = useState(false)
  const [formVisible, setFormVisible] = useState(true)
  const [status, setStatus] = useState({
    queued: true,
    running: false,
    runningStart: false,
    loaded: false,
    loadedStart: false,
  })
  const [errorMsg, setErrorMsg] = useState('')
  const [existingCust, setExistingCust] = useState(false)
  const [formSubmitReady, setFormSubmitReady] = useState(false)
  const [scoreData, setScoreData] = useState({})
  const [isClient, setIsClient] = useState(false)
  const [scannerCount, setScannerCount] = useState(0)
  const { showConsentForm } = useShowConsentForm()

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    scannerScoreUrl: '',
    baseScannerUrl: '',
    form_name: 'Scanner Gate Form',
  })

  const updateFormData = prop => setFormData({ ...formData, ...prop })
  const updateStatus = prop => setStatus({ ...status, ...prop })

  const variant = partner ? 'sales' : 'onboarding'

  const languageData = generateScanResultsPageTranslations({ userLang })

  const formIds = {
    production: {
      consentRequired: {
        en: '8b309af4-df56-49e0-8fbb-5594d2b7d0c0',
        de: 'b5bae341-8949-4536-ad0d-0e954f44e18d',
        fr: '43684b8c-cba4-40c3-b7e7-d038d14ddbaf',
        es: '4294ab5d-02d5-4240-8417-a48a22c9c8a9',
        it: 'f37ee2c4-303d-48ab-9528-171b392891e2',
      },
      noConsentRequired: {
        en: '1df19fc6-9766-4fa5-929f-35611e311617',
        de: '88878183-7264-43c6-8dd4-5efe8870190f',
        fr: 'b0ac00a7-910c-4f28-99c6-f1e52c288847',
        es: '04ca86ee-eed3-4648-89b9-56e9d381bfb6',
        it: '1df78e81-c1fb-4ed2-9276-b347846755f4',
      },
    },
    staging: {
      consentRequired: {
        en: 'ddce435f-f271-4ddc-b89a-2aa27453b467',
        de: 'db2a0cab-5fde-469c-be06-bd82ee008006',
        fr: '62d912af-f472-4aa9-967e-0a97ebe43dbf',
        es: '41e8be7d-b34e-461c-8736-ffe8e094e8c8',
        it: '871d7d8a-6782-442a-9554-1537ae27c122',
      },
      noConsentRequired: {
        en: '798dfa9b-cfe7-40cb-bc3b-4e35f25315b0',
        de: '16435bdc-a31d-44b6-80f6-74018a8a1b36',
        fr: '91fa09bf-3881-4ede-9e35-99e977109dd8',
        es: 'a6350b08-7eed-413c-afc2-e280926b5fe5',
        it: 'ffb5207f-1731-4e01-aa03-d6362241e7a5',
      },
    },
  }

  const environment = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'production' : 'staging'
  const consent = showConsentForm ? 'consentRequired' : 'noConsentRequired'
  const lang = userLang

  const formID = formIds[environment][consent][lang]

  // show lead modal if not a partner (if partner query param = false) + showGateForm is true and no uuid in URL
  const onShowForm = () => {
    if (
      (partner === 'false' || !partner) &&
      (mktg === 'false' || !mktg) &&
      showGateForm !== 'false' &&
      !useUrlParam(window.location.search, 'uuid')
    ) {
      setFormVisible(true)
    } else {
      setFormVisible(false)
    }
  }

  const validateSite = async url => {
    const siteValidityResponse = await checkSiteValidity(url)
    const siteValidityBody = (await siteValidityResponse.json()) || ''

    if (siteValidityResponse.status === 409) {
      trackEvent(`API Exception`, {
        Label: 'URL already registered with AudioEye',
        'Full Exception': siteValidityBody,
      })
      return false
    }
    return true
  }

  useEffect(() => {
    if (typeof useUrlParam(window.location.search, 'url') === 'undefined') {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    const fetchScore = async () => {
      // Start - if site grader has not yet kicked off scan
      if (!urlId || window.sessionStorage.getItem('gate') === 'true') {
        const rawUrl = useUrlParam(window.location.search, 'url')
        const decodedUrl = decodeURIComponent(rawUrl)
        if (decodedUrl) {
          setScanUrl(decodedUrl)
          // check if URL is an active URL in AE already
          const isValidSite = await validateSite(decodedUrl)
          if (!isValidSite && (mktg === 'false' || !mktg)) {
            // show that the url already exists - don't show lead modal or kick off scan
            setExistingCust(true)
            setDataLoaded(true)
          } else {
            trackEvent(`Site Scan Started`, {
              'Site URL': scanUrl,
              'Site Scan Invoked By': variant,
            })

            const uuidInUrl = useUrlParam(window.location.search, 'uuid')

            // kick off scan
            const uuid = await getUrlId(scanUrl, updateStatus, setErrorMsg, variant)

            if (uuidInUrl) {
              onShowForm()
            }

            if (uuid && !uuidInUrl) {
              setUrlId(uuid)
            } else {
              trackEvent(`Site Scan Failed`, {
                'Site URL': scanUrl,
                'Site Scan Invoked By': variant,
                'Job Status': 'invalid URL',
              })
            }
          }
        }
      } else if (urlId) {
        onShowForm()
      }
    }
    fetchScore()
  }, [])

  // if site grader request has been made (if urlId)
  //  && we haven't gotten a response with data
  //  && we haven't gotten an error response
  //  && we haven't gotten an existing customer response,
  // then check the status of the scan every second (and get results if complete)
  const clear = useInterval(async () => {
    if (urlId && !dataLoaded && !errorMsg && !existingCust && scannerCount < 120) {
      fetchUrlScore(
        urlId,
        setErrorMsg,
        variant,
        updateStatus,
        setScoreData,
        status,
        setDataLoaded,
        updateFormData,
        partner,
        userLang,
        mktg,
        scanUrl,
        onShowForm
      )
      setScannerCount(prev => prev + 1)
    } else if (scannerCount === 120) {
      setErrorMsg(`general-issue`)
      trackEvent(`Site Scan Failed`, {
        'Site URL': scanUrl,
        'Site Scan Invoked By': variant,
        'Job Status': 'scan service unresponsive',
      })
    }
  }, 2000)

  // update internal status variables
  useEffect(() => {
    if (status.running) {
      updateStatus({ runningStart: true })
    }
    if (status.loaded) {
      updateStatus({ loadedStart: true })
    }
  }, [status.running, status.loaded])

  useEffect(() => {
    if (status.runningStart) {
      trackEvent(`Site Scan Running`, {
        'Site URL': scanUrl,
        'Site Scan Invoked By': variant,
        'Job Status': 'running',
        'Failed Reason': '',
      })
    }
  }, [status.runningStart])

  useEffect(() => {
    if (status.loadedStart) {
      trackEvent(`Site Scan Loaded`, {
        'Site URL': scanUrl,
        'Site Scan Invoked By': variant,
        'Job Status': 'loaded',
        'Failed Reason': '',
      })
    }
  }, [status.loadedStart])

  // sends info to HubSpot after lead modal submitted
  useEffect(() => {
    if (formSubmitReady === true) {
      scannerGateHsFormCollect(formData, scoreData, scanUrl, showConsentForm, formID, userLang)
    }
  }, [formSubmitReady, formData, showConsentForm])

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (errorMsg || dataLoaded) {
    clear()
  }

  return (
    <>
      <SEO title={`${languageData?.metaTitle} | AudioEye`} pathname={windowGlobal ? window.location.pathname : null} />
      {isClient && (formVisible || errorMsg) && (
        <ScannerProgress
          variant={variant}
          formData={formData}
          updateFormData={updateFormData}
          setFormVisible={setFormVisible}
          setFormSubmitReady={setFormSubmitReady}
          scoreData={scoreData}
          scanUrl={scanUrl}
          dataLoaded={dataLoaded}
          errorMsg={errorMsg}
          existingCust={existingCust}
          partner={partner}
          showGateForm={showGateForm}
          formID={formID}
        />
      )}

      {isClient && (
        <ResultsContent
          formVisible={formVisible}
          errorMsg={errorMsg}
          dataLoaded={dataLoaded}
          existingCust={existingCust}
          scoreData={scoreData}
          scanUrl={scanUrl}
          partner={partner}
        />
      )}
    </>
  )
}

export default ScannerContent
