import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { AutoFocusInside } from 'react-focus-lock'
import { identify, trackEvent } from '../../utils/eventTrack'

import Text from '../common/Text'
import Button from '../common/Button'
import Input from './Input'
import Select from './Select'
import Space from '../common/Space'
import PhoneNumberInput from './PhoneNumberInput'
import { NameInputWrapper, InputContainer, ButtonWrapper } from './common'
import parseFullName from '../../utils/parseFullName'
import { ConsentFormFields } from './Onboarding/ConsentFormFields'
import { useShowConsentForm } from '../../context/ConsentContext'
import generateScannerGateTranslations from '../../data/generateScannerGateTranslations'
import { useUserLang } from '../../context/UserLangContext'

const GateForm = styled.form`
  width: 100%;
`

const HiddenForm = styled.div`
  display: none;
`

export const ValidatedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export default function ScannerGate({
  updateFormData,
  setFormVisible,
  formData,
  setFormSubmitReady,
  variant,
  scanUrl,
  formID,
}) {
  const { userLang } = useUserLang()
  const { showConsentForm } = useShowConsentForm()
  const [showEduQuestion, setShowEduQuestion] = React.useState(false)

  const languageData = generateScannerGateTranslations({ userLang })

  const windowGlobal = typeof window !== `undefined` && window

  const submit = async evt => {
    evt.preventDefault()

    setFormVisible(false)
    setFormSubmitReady(true)

    const { firstName, lastName } = parseFullName(formData.fullName)

    const storageData = [
      {
        name: 'firstname',
        value: firstName,
      },
      { name: 'lastname', value: lastName },
      { name: 'email', value: formData.email },
      { name: 'phone', value: formData.phone },
      { name: 'education_role', value: formData.educationRole },
      { name: 'legal_consent_subscription', value: formData.legalConsentSubscription },
      { name: 'legal_consent_processing', value: formData.legalConsentProcessing },
    ]

    window.sessionStorage.setItem('hs-form-data', JSON.stringify(storageData))
    window.sessionStorage.setItem('agency_viewed', true)

    identify({
      email: formData.email,
    })
  }

  const createForm = () => {
    if (!window.hbspt) {
      setTimeout(createForm, 1)
    } else {
      const data = {
        'Site URL': scanUrl,
        'Site Scan Invoked By': variant,
      }

      trackEvent(`Site Scan Lead Form Viewed`, data)
      window.hbspt.forms.create({
        portalId: `${process.env.GATSBY_HUBSPOT_PORTAL_ID}`,
        formId: formID,
        target: '#hidden-form',
      })

      return true
    }
  }

  const loadScript = () => {
    const script = document.createElement(`script`)
    script.defer = true
    script.onload = () => {
      createForm()
    }
    script.src = `//js.hsforms.net/forms/v2.js`
    document.head.appendChild(script)
  }

  /*
    This code was pulled from react-hubspot-form
    Doing this in order to create a page/form view but not actually using the
    generated html form from hubspot. uUing our own custom form because we need to fill
    some hidden fields/add custom fields/etc
  */
  useEffect(() => {
    if (windowGlobal) {
      if (!window.hbspt) {
        loadScript()
      } else {
        createForm()
      }
    }
  }, [])

  return (
    <>
      <Text heading4 color="purple500" aria-level="2">
        {languageData?.eyebrow}
      </Text>
      <Space height={32} />
      <HiddenForm id="hidden-form" />
      <GateForm onSubmit={submit}>
        <NameInputWrapper>
          <AutoFocusInside className="auto-focus-inside">
            <Input
              label={languageData?.fullNameLabel}
              placeholder=""
              autoComplete="given-name"
              name="full_name"
              onChange={e => updateFormData({ fullName: e.target.value })}
              value={formData.fullName}
              required
              autoCapitalize="words"
              id="name-input"
            />
          </AutoFocusInside>
        </NameInputWrapper>
        <Space height={8} />
        <InputContainer>
          <Input
            label={languageData?.businessEmailLabel}
            placeholder=""
            name="email"
            inputMode="email"
            type="email"
            required
            value={formData.email}
            onChange={e => {
              if (e.target.value.includes('.edu')) {
                setShowEduQuestion(true)
              } else {
                setShowEduQuestion(false)
              }

              updateFormData({ email: e.target.value })
            }}
          />
        </InputContainer>
        {showEduQuestion && (
          <InputContainer>
            <Select
              label={languageData?.educationRoleLabel}
              options={[
                { label: 'Student', value: 'Student' },
                { label: 'Professor', value: 'Professor' },
                { label: 'Faculty / Administration', value: 'Faculty / Administration' },
                { label: 'Other', value: 'Other' },
              ]}
              name="education_role"
              required
              value={formData.education_role}
              onChange={e => {
                updateFormData({ education_role: e.target.value })
              }}
            />
          </InputContainer>
        )}
        <Space height={8} />
        <InputContainer id="phone-wrapper">
          <ValidatedInputWrapper>
            <PhoneNumberInput
              updateFormData={updateFormData}
              formData={formData}
              id="phone-input"
              labelText={languageData?.phoneNumberField}
            />
          </ValidatedInputWrapper>
        </InputContainer>
        <Space height={8} />
        {showConsentForm && (
          <>
            <ConsentFormFields formData={formData} updateFormData={updateFormData} />
            <Space height={16} />
          </>
        )}
        <ButtonWrapper>
          <Button text={languageData?.submitButtonText} type="submit" id="get-results-btn" wordWrap />
        </ButtonWrapper>
        {userLang !== 'en' && (
          <>
            <Space height={16} />
            <Text tinyBody color="gray700">
              {languageData?.emailCommunications}
            </Text>
          </>
        )}
      </GateForm>
    </>
  )
}
